<template>
  <v-container>
    <v-img class="mx-auto" max-width="240" src="../assets/default.jpg" />
    <v-row>
      <v-col>
        <h1 class="font-weight-medium">{{ $t("reportLander.title") }}</h1>
        <p>{{ $t("reportLander.info_1") }}</p>
        <p>
          {{ $t("reportLander.example") }}: https://ilmoita.vihjaa.fi/{{
            $t("reportLander.organizationName")
          }}
        </p>
        <p>{{ $t("reportLander.info_2") }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
h1 {
  font-size: 28px;
  margin: 25px 0;
}
</style>

<script>
export default {};
</script>
